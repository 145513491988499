<template>
  <div
    class="g-recaptcha"
    style="z-index: 10000"
    :data-sitekey="recaptchaToken"
    data-callback="recaptchaScript"
    data-expired-callback="recaptchaExpired"
    data-error-callback="recaptchaError"
    data-size="invisible"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      scriptEl: null,
      recaptchaToken: process.env.VUE_APP_RECAPTCHA
    };
  },
  methods: {
    async execute() {
      try{

        if (!window.grecaptcha) return this.$emit("error");

      let current = window.grecaptcha.getResponse();
      if (current) window.grecaptcha.reset();
      window.grecaptcha.execute();
      } catch (e) {
        this.$emit("error", e);
      }
    },
    beforeExecute(response) {
      this.$emit("success", response);
    },
    onError(response) {
      this.$emit("error", response);
    },
    onExpired(response) {
      this.$emit("expired", response);
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");

    this.scriptEl = recaptchaScript.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    document.head.appendChild(recaptchaScript);
    window.recaptchaScript = this.beforeExecute;
    window.recaptchaScriptError = this.onError;
    window.recaptchaScriptExpired = this.onExpired;
  },
  destroyed() {
    if (this.scriptEl) {
      document.head.removeChild(this.scriptEl);
    }
  }
};
</script>

<style></style>
