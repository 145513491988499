<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card :loading="fullLoading && !error">
        <v-card-title class="d-flex align-center justify-center py-7">
          <v-img
            :src="require(`@/assets/images/logos/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
            max-height="auto"
            max-width="250px"
            alt="logo"
            contain
            class=""
          ></v-img>
        </v-card-title>

        <v-card-text v-if="!fullLoading">
          <p class="text-2xl font-weight-light text--primary text-center mb-0">Alterar Senha!</p>
          <p class="text-center mb-4">Para alterar a sua senha insira uma nova e confirme.</p>
          <v-text-field
            v-model="password"
            :type="passwordVisible ? 'text' : 'password'"
            :append-icon="passwordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            label="Nova Senha"
            outlined
            dense
            :error-messages="password.length ? validatePassword : []"
            @click:append="passwordVisible = !passwordVisible"
          >
          </v-text-field>
          <v-text-field
            v-model="passwordConfirm"
            :type="passwordConfirmVisible ? 'text' : 'password'"
            :append-icon="passwordConfirmVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            label="Confirmar Nova Senha"
            outlined
            dense
            class="mt-3"
            :error-messages="passwordConfirm.length ? validatePasswordConfirm : []"
            @click:append="passwordConfirmVisible = !passwordConfirmVisible"
          ></v-text-field>

          <v-btn @click="run" :loading="loading" :disabled="loading || block" block x-large color="primary" class="mt-6"
            >Alterar Senha</v-btn
          >
        </v-card-text>
        <v-card-text v-else-if="!error">
          <v-alert outlined :value="true" type="info" icon="mdi-key" class="mb-0">
            <span class="font-weight-bold">Verificando seu link!</span>
            <p class="mb-0">
              Por favor, aguarde um momento
            </p>
          </v-alert>
        </v-card-text>
        <v-card-text v-else>
          <v-alert outlined :value="true" type="error" icon="mdi-alert-circle-outline" class="mb-0">
            <span class="font-weight-bold">Ops!</span>
            <p class="mb-0">
              {{ error }}
            </p>
          </v-alert>
        </v-card-text>
      </v-card>
    </div>
    <re-captcha ref="recaptcha" @success="processRequest" @error="throwError"></re-captcha>
  </div>
</template>

<script>
import ReCaptcha from "@/components/global/ReCaptcha.vue";
import auth from "@/services/auth";

export default {
  components: { ReCaptcha },
  data: () => ({
    password: "",
    passwordConfirm: "",
    passwordVisible: false,
    passwordConfirmVisible: false,
    loading: false,
    block: false,
    token: "",
    fullLoading: true,
    error: false
  }),
  methods: {
    run() {
      try {
        if (this.validatePassword.length || this.validatePasswordConfirm.length)
          throw { message: "Preencha os campos corretamente!" };
        this.loading = true;
        this.$refs.recaptcha.execute();
      } catch (e) {
        this.throwError(e);
      }
    },
    processRequest(token) {
      if(this.fullLoading) this.verifyCode(token)
      else this.changePassword(token)
    },
    async verifyCode(token){
      try{
        let code = this.$route.query.t;
        let type = await auth.resetPassword.verifyCode(code, token);
        this.fullLoading = false;
      } catch (e) {
        this.error = e.message || "Ocorreu um erro ao verificar o código!";
      }
    },
    async changePassword(token){
      try {
        let code = this.$route.query.t;
        this.block = true;
        await auth.resetPassword.setPassword(code, this.password, token);
        this.loading = false;
        this.$toast
          .fire({
            icon: "success",
            title: "Senha alterada com sucesso!"
          })
          .then(() => {
            this.$router.push("/");
          });
      } catch (e) {
        this.throwError(e);
      }
    },
    throwError(error) {
      this.$toast.fire({
        title: error.message || "Erro ao validar captcha",
        icon: "error"
      });
      this.loading = false;
    }
  },
  mounted() {
    setTimeout(this.$refs.recaptcha.execute, 3000);
  },
  computed: {
    validatePassword() {
      let errors = [];
      let p = this.password;
      if (!/(?=.*?[a-z])/gm.test(p)) errors.push("A senha deve conter pelo menos uma letra minúscula.");
      if (!/(?=.*?[A-Z])/gm.test(p)) errors.push("A senha deve conter pelo menos uma letra maiúscula.");
      if (!/(?=.*?[0-9])/gm.test(p)) errors.push("A senha deve conter pelo menos um número.");
      if (!/(?=.*?[#?!@$%^&*-])/gm.test(p)) errors.push("A senha deve conter pelo menos um carácter especial.");
      if (p.length < 8) errors.push("A senha deve conter pelo menos 8 caracteres.");

      return errors;
    },
    validatePasswordConfirm() {
      let errors = [];
      if (this.password !== this.passwordConfirm) errors.push("As senhas não conferem.");
      return errors;
    }
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
